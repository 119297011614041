import { Tenant } from '@whitelabel/Entities/Tenants.entity';
import { ServiceConsumer } from '@mobile-app/Entities/ServiceConsumer.entity';

export const generateSnowplowAppId = ({ app }: { app?: Tenant | ServiceConsumer }) => {
  const suffix: { [key: string]: string } = {
    staging: '_stg',
    development: '_dev',
    production: '',
  };

  return `${app || 'i15'}${
    suffix?.[process.env.NEXT_PUBLIC_APP_ENV || 'development'] ?? suffix.development
  }`;
};
